<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <!-- 検索エリア -->
          <manager-search-area />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ManagerSearchArea from "../components/ManagerSearchArea.vue";

export default {
  name: "ManagerSearch",

  components: {
    ManagerSearchArea,
  },
  data: () => ({
  }),
  computed: {},
  methods: {
  },
};
</script>