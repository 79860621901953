<template>
  <v-form ref="form1">
    <!-- 担当者検索画面 -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>担当者検索</v-card-title>
          <v-card-subtitle>部門コード・権限区分は管理者のみ使用できます。</v-card-subtitle>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <!-- 部門コード -->
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="departmentCode"
                    label="部門コード"
                    :items="departmentCodeList"
                    item-text="comboItem"
                    item-value="code"
                    clearable
                    :hint="`完全一致`"
                    persistent-hint
                    @change="updateDepartmentCodeCombo"
                    :disabled="!isLoginUserAuth"
                  >
                  </v-autocomplete>
                </v-col>
                <!-- 権限区分 -->
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="managerAuthSection"
                    label="権限区分"
                    :items="managerAuthSectionList"
                    item-text="comboItem"
                    item-value="code"
                    clearable
                    :hint="`完全一致`"
                    persistent-hint
                    :disabled="!isLoginUserAuth"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <!-- 担当者コード -->
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="managerCode"
                    label="担当者コード"
                    :items="managerList"
                    item-text="comboItem"
                    item-value="code"
                    clearable
                    :hint="`完全一致`"
                    persistent-hint
                    @change="updateCodeCombo"
                    :disabled="isLoginUserAuth && departmentCode == null"
                  >
                  </v-autocomplete>
                </v-col>
                <!-- 担当者名 -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="managerName"
                    label="担当者名"
                    counter="4"
                    :rules="[limit_length4]"
                    :hint="`部分一致`"
                    persistent-hint
                    clearable
                    @change="updateNameCombo"
                    :disabled="isDisableText"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-5"></v-divider>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <!-- 検索ボタン -->
            <v-btn color="secondary" v-on:click="onClickSearch" rounded>
              <v-icon left> mdi-magnify </v-icon>検索
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="searchResultItems"
              class="elevation-5 mt-5"
              item-key="searchItemId"
              multi-sort
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>検索結果</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-toolbar-title>
                    {{ searchResultItems.length }}件
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- 新規登録ボタン -->
                  <v-btn dark color="secondary" rounded @click="insertItem()">
                    <v-icon left>mdi-pen-plus</v-icon>新規登録
                  </v-btn>
                </v-toolbar>
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-container>
                  <v-row>
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on, attrs }">
                        <template v-if="transitionFlg == true">
                          <v-btn
                            class="m-0"
                            fab
                            dark
                            x-small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectItem(item)"
                          >
                            <v-icon>mdi-checkbox-marked-circle </v-icon>
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn
                            class="m-0"
                            fab
                            dark
                            x-small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(item)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn></template
                        ></template
                      >
                      <span>{{ tooltipText }}</span>
                    </v-tooltip>
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on, attrs }">
                        <template v-if="transitionFlg == true"> </template>
                        <template v-else>
                          <v-btn
                            class="ml-1"
                            fab
                            dark
                            x-small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteItem(item)"
                            :disabled="!isLoginUserAuth"
                            ><v-icon>mdi-delete</v-icon>
                          </v-btn></template
                        ></template
                      >
                      <span>削除</span>
                    </v-tooltip>
                  </v-row>
                </v-container>
                <v-spacer></v-spacer>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// graphqlのインポート
import { Amplify, API, Auth, graphqlOperation } from "aws-amplify";
import AWS from 'aws-sdk';
import {
  listM_TANTOSHA,
  listM_SHOKISETTEI,
} from "@/graphql/queries";
import { executeTransactSql } from "@/graphql/mutations";
import { AWS_DEFAULT_REGION, MANAGER_KIND_LIST } from "@/assets/js/const";
import { CONFIRM_MESSAGE_DELETE_DATA, ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import { base64Encode } from '@/assets/js/common';
export default {
  name: "ManagerSearchArea",

  props: {
    transitionFlg: Boolean,
    reqManagerCode: Number,
    dialogManagerSearch: Boolean,
  },

  data: () => ({
    // 編集インデックス
    editedIndex: -1,
    // Datatablesのヘッダー
    headers: [
      { text: "操作", value: "actions", sortable: false, width: "100px" },
      { text: "部門コード", value: "departmentCode", width: "80px" },
      { text: "担当者コード", value: "managerCode", width: "80px" },
      { text: "担当者名（姓）", value: "managerFamilyName", width: "50px" },
      { text: "担当者メールアドレス", value: "managerAddress", width: "100px" },
      { text: "承認者名（姓）", value: "authorizerFamilyName", width: "50px" },
      { text: "承認者メールアドレス", value: "authorizerAddress", width: "100px"},
      { text: "権限区分", value: "managerAuthSection", width: "100px" },
    ],
    // Datatablesのデータ
    searchResultItems: [],

    // 検索条件  担当者名
    managerName: null,
    // 検索条件  担当者コード
    managerCode: null,
    // 検索条件  部門コード
    departmentCode: null,
    // 検索条件  権限区分
    managerAuthSection: null,
    // 検索用リスト（コード、名前）
    managerList: [],

    // 新規登録用部門コードリスト
    departmentCodeList: [],
    // 新規登録用権限リスト
    managerAuthSectionList: MANAGER_KIND_LIST,

    // 検索用テキストボックス無効化フラグ
    isDisableText: false,

    // ログインユーザー情報
    loginUserInfo: null,

    // 検索結果の一意キー
    searchItemId: null,

    // 入力規則
    // 文字数の制約 最大4文字
    limit_length4: (value) =>
      value == null || value.length <= 4 || "4文字以内で入力してください",
  }),
  /**
   * computed 算出プロパティ
   */
  computed: {
    /**
     * ログイン中ユーザーの管理者チェック
     */
    isLoginUserAuth() {
      let loginUser = this.managerAuthSectionList.find(
        (items) => items.code == this.$store.getters.user.TANTOSHA_AUTH_KBN
      );
      // リストに存在しない区分はfalse
      return loginUser ? loginUser.isAuthUser : false;
    },
    /**
     * ツールチップタイトル設定
     */
    tooltipText() {
      return this.transitionFlg === true ? "選択" : "修正";
    },
  },

  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (this.loginUserInfo !== null);
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // コンボボックス用リストの取得
      // 検索条件
      let condition = {
        where_options: " ORDER BY BUMON_CD ASC",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // コンボボックス用リストの取得
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.listM_SHOKISETTEI) {
          // データを取得できた場合
          // 部門コード選択用コンボボックスのリストに追加
          let resultData = result.data.listM_SHOKISETTEI;
          for (const data of resultData) {
            this.departmentCodeList.push({
              comboItem: data.BUMON_CD + "：" + data.BUMON_NAME,
              code: data.BUMON_CD,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 一般ユーザーの場合は、所属部門の担当者コードを取得
      let loginUser = this.managerAuthSectionList.find(
        (items) => items.code == this.loginUserInfo.TANTOSHA_AUTH_KBN
      );
      if (loginUser && !loginUser.isAuthUser) {
        // 検索条件
        condition = {
          where_options:
            "AND BUMON_CD = " +
            this.loginUserInfo.BUMON_CD +
            " ORDER BY TANTOSHA_CD ASC",
        };
        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        // データ取得
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(listM_TANTOSHA, condition)
          );
          // 結果判定
          if (result.data.listM_TANTOSHA) {
            // データを取得できた場合
            // 取得結果をリストに追加
            let resultData = result.data.listM_TANTOSHA;
            this.managerList = [];
            for (const data of resultData) {
              this.managerList.push({
                comboItem: data.TANTOSHA_CD + "：" + data.TANTOSHA_NAME,
                code: data.TANTOSHA_CD,
              });
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          } else {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        } catch (error) {
          // Exception発生の場合
          // @TODO 異常系の処理を以下に記載。
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
          return false;
        }
      }
      // 検索条件の初期化
      this.managerName = null;
      this.managerCode = null;
      this.departmentCode = null;
      this.managerAuthSection = null;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 新規登録ボタン押下時処理
     */
    insertItem() {
      // メソッド名を定義
      const method_name="insertItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.$router.push({ name: "ManagerInsert" });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ボタン押下時処理
     */
    async editItem(item) {
      if (!this.isLoginUserAuth && item.managerAuthSection === "管理者") {
        alert("一般ユーザーは管理者ユーザーの情報を編集できません。");
        return;
      }
      console.log({item});
      const itemString = JSON.stringify(item);
      try {
        // 入力画面に編集対象の担当者コードを渡す
        this.$router.push({
          name: "ManagerInsert",
          query: {
            reqManagerItemString: await base64Encode(itemString),
          },
        });
      } catch (error) {
        //TODO エラー処理
      }
    },
    /**
     * 選択ボタン押下時処理
     */
    selectItem(item) {
      // メソッド名を定義
      const method_name="selectItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.$emit("result", {
        returnNo: this.reqManagerCode,
        returnManagerCode: item.managerCode,
      });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 削除ボタン押下時処理
     */
    async deleteItem(item) {
      // メソッド名を定義
      const method_name="deleteItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      this.editedIndex = this.searchResultItems.indexOf(item);

      if (item.loginUserCode === this.loginUserInfo.LOGIN_USER_CD) {
        alert("ログイン中のユーザーは削除できません。");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      if (confirm(CONFIRM_MESSAGE_DELETE_DATA)) {
        // ローディング画面の開始
        this.$store.commit("setLoading", true);

        // DBからデータ削除
        // 削除条件
        let sqlList = [
          `DELETE FROM M_TANTOSHA WHERE LOGIN_USER_CD = '${item.loginUserCode}'`
        ];
        let condition = {
          SQLs: sqlList,
        };
        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        // 実行結果取得
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(executeTransactSql, condition)
          );
          // レスポンスデータを取得できた際の処理
          if (result) {
            const responseBody = JSON.parse(
              result.data.executeTransactSql.body
            );

            // SQL実行でエラーが発生した場合の処理
            if (result.data.executeTransactSql.statusCode > 200) {
              console.info({ responseBody });
              // レスポンスメッセージ
              let message = responseBody.message;
              console.error({ message });
              // エラー内容
              const errorMessage = responseBody.error;
              console.error({ errorMessage });
              // ログ出力(Lamda関数でのSQL実行でステータスエラー)
              addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            } else {
              // SQLが正常に終了した際の処理
              if (responseBody.data) {
                // SELECT文の結果はresponseBody.dataとして返却される
                // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
                for (const rows of responseBody.data) {
                  console.dir(rows, { depth: null });
                }
                // 検索結果からアイテムを削除
                this.searchResultItems = this.searchResultItems.filter(
                    searchResultUser => (searchResultUser.loginUserCode !== item.loginUserCode)
                );

                // Cognitoの認証情報を取得
                const credentials = await Auth.currentCredentials();

                // Cognitoユーザー削除
                this.deleteCognitoUser(credentials, item.managerAddress);

                // SESの認証済みメールアドレスを削除
                this.deleteSESIdendity(credentials, item.managerAddress);
              }
              // ログ出力(SQL-end_OK)
              addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            }
            console.info(JSON.parse(result.data.executeTransactSql.body));
          } else {
            // レスポンスデータを取得できなかった際の処理
            console.log("deleteTables : " + JSON.stringify(result));
            // ログ出力(Lamda関数でのSQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        } catch (error) {
          console.log("deleteTables : 異常終了 = " + JSON.stringify(error));
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
          return false;
        }

        // 初期化処理の実行
        if (!(await this.initialize())) {
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 検索条件の初期化
        this.updateCodeCombo();
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * Cognitoユーザー削除処理
     */
    async deleteCognitoUser(credentials, mailAddress) {
      // メソッド名を定義
      const method_name="deleteCognitoUser"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(Amplify-start)
      addLog('INFO', {message: MSG.INFO["1008"],}, this.$route.name, method_name, TYPE[operation_type],);
      try {
        // Cognito User Poolの操作クラスにAWS認証情報を渡して初期化する
        const cognitoIdentity = new AWS.CognitoIdentityServiceProvider({
          credentials: Amplify.Auth.essentialCredentials(credentials),
          region: AWS_DEFAULT_REGION,
        });
        // Cognitoの情報からユーザープールIDを取得
        const currentUserPoolUser = await Auth.currentUserPoolUser();
        const UserPoolId = currentUserPoolUser.pool.userPoolId;

        // Cognitoユーザー作成
        await cognitoIdentity.adminDeleteUser({
          UserPoolId: UserPoolId,
          Username: mailAddress.split('@')[0],
        }).promise();
      } catch (error) {
        // TODO エラーログ
        console.log('deleteCognitoUser');
        console.log(error);
        // ログ出力(Amplifyの実行でexceptionが発生)
        addLog('ERROR', {message: MSG.ERROR["3012"],}, this.$route.name, method_name, TYPE["0004"], error);
      }
      // ログ出力(Amplify-end)
      addLog('INFO', {message: MSG.INFO["1009"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * SESの認証済みメールアドレス削除処理
     */
    async deleteSESIdendity(credentials, mailAddress) {
      // メソッド名を定義
      const method_name="deleteSESIdendity"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(Amplify-start)
      addLog('INFO', {message: MSG.INFO["1008"],}, this.$route.name, method_name, TYPE[operation_type],);
      try {
        const ses = new AWS.SES({
          credentials: Amplify.Auth.essentialCredentials(credentials),
          region: AWS_DEFAULT_REGION,
        });

        const result = await ses.deleteIdentity({Identity: mailAddress}).promise();
        console.log(result);
      } catch (error) {
        console.log('deleteSESIdendity');
        console.log(error);
        // ログ出力(Amplifyの実行でexceptionが発生)
        addLog('ERROR', {message: MSG.ERROR["3012"],}, this.$route.name, method_name, TYPE["0004"], error);
      }
      // ログ出力(Amplify-end)
      addLog('INFO', {message: MSG.INFO["1009"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 検索ボタン押下時
     */
    async onClickSearch() {
      // メソッド名を定義
      const method_name="onClickSearch"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 検索結果格納用リストの初期化
      this.searchResultItems = [];

      // 画面上の検索条件でWHERE句を作成
      let whereOptions = this.setWhereOptions();

      // 検索結果の取得
      let resultData = await this.getListM_TANTOSHA(whereOptions);
      if (!resultData) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディング画面を解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      let manager = null;
      for (const data of resultData) {
        manager = this.managerAuthSectionList.find(
          (items) => items.code == data.TANTOSHA_AUTH_KBN
        );
        this.searchResultItems.push({
          loginUserCode: data.LOGIN_USER_CD,
          managerCode: data.TANTOSHA_CD,
          departmentCode: data.BUMON_CD,
          managerFamilyName: data.TANTOSHA_NAME,
          managerAddress: data.TANTOSHA_ADDRESS,
          managerAuthSection: (manager && manager.isAuthUser) ? "管理者" : "一般",
          authorizerFamilyName: data.SHONINSHA_SIGN_NAME,
          authorizerAddress: data.SHONINSHA_ADDRESS,
          createdDate: data.CREATE_DATE,
          updatedDate: data.UPDATE_DATE,
          searchItemId: String(data.BUMON_CD) + String(data.TANTOSHA_CD)
        });
      }
      // ローディング画面を解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 担当者マスタへのリスト取得処理
     */
    async getListM_TANTOSHA(whereOptions) {
      // メソッド名を定義
      const method_name="getListM_TANTOSHA"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 検索条件
      let condition = {
        where_options: whereOptions,
      };
      console.log("condition = " + JSON.stringify(condition));
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_TANTOSHA, condition)
        );

        // 結果判定
        if (result.data.listM_TANTOSHA) {
          // データを取得できた場合
          let resultData = result.data.listM_TANTOSHA;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return resultData;
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
    /**
     * コードのコンボボックス更新時処理
     */
    updateCodeCombo() {
      // メソッド名を定義
      const method_name="updateCodeCombo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // テキストボックスの無効化
      this.isDisableText = null !== this.managerCode;
      // 名前をクリア
      this.managerName = null;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * コードのコンボボックス更新時処理
     */
    async updateDepartmentCodeCombo() {
      // メソッド名を定義
      const method_name="updateDepartmentCodeCombo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 検索条件
      let condition = {
        where_options:
          "AND BUMON_CD = " +
          this.departmentCode +
          " ORDER BY TANTOSHA_CD ASC",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_TANTOSHA, condition)
        );
        // 結果判定
        if (result.data.listM_TANTOSHA) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_TANTOSHA;
          this.managerList = [];
          for (const data of resultData) {
            this.managerList.push({
              comboItem: data.TANTOSHA_CD + "：" + data.TANTOSHA_NAME,
              code: data.TANTOSHA_CD,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ローディング画面を解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 名前のコンボボックス更新時処理
     */
    updateNameCombo() {
      // メソッド名を定義
      const method_name="updateNameCombo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 名前の入力・選択の際はコードをクリア
      this.isDisableText = false;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 検索条件用のWHERE句生成処理
     */
    setWhereOptions() {
      // メソッド名を定義
      const method_name="setWhereOptions"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let whereOptions = "";

      // 検索条件 部門コード（一般ユーザーの時は所属部門コード）
      let loginUser = this.managerAuthSectionList.find(
        (items) => items.code == this.loginUserInfo.TANTOSHA_AUTH_KBN
      );
      whereOptions += (loginUser && !loginUser.isAuthUser)
          ? ` AND BUMON_CD = ${this.loginUserInfo.BUMON_CD}`
          : this.departmentCode == null ? `` : ` AND BUMON_CD = ${this.departmentCode}`;
 
      // 検索条件 権限区分
      whereOptions += this.managerAuthSection == null ? `` : ` AND TANTOSHA_AUTH_KBN = '${this.managerAuthSection}'`;

      // 検索条件 担当者コード
      whereOptions += this.managerCode == null ? `` : ` AND TANTOSHA_CD = ${this.managerCode}`;

      // 検索条件 担当者名
      whereOptions += this.managerName == null ? `` : ` AND TANTOSHA_NAME LIKE '%${this.managerName}%'`;

      // ソート順の指定（担当者コード順）
      whereOptions += ` ORDER BY BUMON_CD ASC, TANTOSHA_CD ASC`;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return whereOptions;
    }
  },
};
</script>
<style scoped>
.v-data-table {
  white-space: nowrap;
}
</style>
